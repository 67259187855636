.container {
  @apply mx-auto p-6;
}

.textCenter {
  @apply text-center w-full mt-12 mb-12;
}

.heading {
  @apply text-3xl font-bold mb-2;
}

.description {
  @apply text-base;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
  justify-items: center;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.teamMember {
  @apply flex flex-col items-center p-4 space-y-3;
}

.avatar {
  @apply w-24 h-24 rounded-full object-cover;
}

.name {
  @apply text-lg font-bold;
}

.position {
  @apply text-base;
}