/* Start by resetting some styles for the header elements */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
    padding-left: 2vw;
    padding-right: 2vw;
    background: black;

    /* background-image: url('../static/assets/header.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */


    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    z-index: 10;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; /* Stick to the top of the viewport */
    overflow: hidden;
    transition: 0.5s ease-in-out;
    height: auto;
  }

  /* Additional styles for when the header becomes sticky */
  .sticky {
    background-color: rgba(0, 0, 0, 0.6); /* add transparency to the background color */
    box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Optionally add more shadow for sticky header */
    transition: 0.5s ease-in-out;
  }
  
  
  .logo-container {
    display: flex;
    align-items: center;
    /* Add some padding if needed */
  }
  
  .logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .logo-img-header {
    height: 70px; /* Fixed height for the logo */
    width: auto;
  }
  
  .logo-text {
    color: #fff; /* Bright color for the text */
    margin-left: 10px;
    font-size: 2rem; /* Larger font size */
  }
  
  .nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }
  
  .nav ul li {
    margin: 0 15px;
  }
  
  .nav ul li a {
    text-decoration: none;
    color: #ecf0f1; /* Light color for the text */
    font-size: 1.3rem;
    transition: color 0.3s ease; /* Smooth transition for color */
  }

  .nav ul li a {
    text-decoration: none;
    color: #fff; /* Example text color */
    padding: 10px; /* Add padding for larger click area */
    position: relative; /* Needed to position the pseudo-elements */
    transition: color 0.3s ease; /* Smooth transition for color */
  }
  
  /* Hover effect for nav links */
  .nav ul li a:hover,
  .nav ul li a:focus { /* Also apply to focus for accessibility */
    color: #00aaff; /* Example hover color */
  }
  
  /* Underline effect on hover */
  .nav ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #00aaff; /* Example underline color */
    transition: width 0.3s;
    position: absolute;
    bottom: -5px; /* Spacing under the link */
    left: 0;
  }
  
  /* Extend the underline on hover */
  .nav ul li a:hover::after,
  .nav ul li a:focus::after { /* Also apply to focus for accessibility */
    width: 100%;
  }
   
  
  .close-btn {
    display: none; /* Hide close button by default */
    color: #fff;
    font-size: 2rem;
  }
  
  .menu-icon {
    display: none;
    cursor: pointer;
  }
  
  .menu-icon div {
    width: 30px;
    height: 3px;
    background-color: #ecf0f1;
    margin: 6px 0;
    transition: transform 0.3s ease; /* Smooth transition for hamburger icon animation */
  }

  .header::after {
    content: '';
    display: block;
    height: 4px; /* Height of the bottom strip */
    background: grey; /* Example color for the strip */
    width: 100%; /* Full width */
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
    animation: pulseAnimation 6s infinite linear; /* Apply the animation with linear timing */
  }
  
  
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transform: translateX(-100%);
      transition: transform 0.3s ease-out;
    }

    .nav.open {
      transform: translateX(0);
    }

    .nav ul {
      width: 100%; /* Full width for the vertical menu */
      display: flex; /* This needs to be overridden */
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center align the items */
    }
    
    .nav ul li {
        width: 100%; /* List items take full width */
        margin: 10px 0; /* Spacing between stacked items */
    }
  
    .menu-icon {
      display: block;
    }

    .close-btn {
        display: block; /* Show the close button */
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
      }

    .header::after {
      z-index: -1;
    }

    .logo-img-header {
      height: 60px; /* Smaller logo for mobile */
    }
  
    .nav ul li a {
      font-size: 2rem; /* Smaller font size for mobile links */
    }
  }
  