.footer {
    background: black; /* Same as the header for consistency */
    color: #ecf0f1; /* Light color for the text */
    padding: 20px;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.2); /* Subtle shadow for depth */
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px; /* Adds some space above the links */
  }
  
  .footer-links a {
    color: #ecf0f1;
    text-decoration: none;
    margin-right: 15px; /* Spacing between links */
    transition: color 0.3s ease;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }

  .contact-section {
    margin-bottom: 20px;
  }
  
  .contact-section h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .contact-section p {
    font-size: 14px;
  }
  
  .contact-section a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-section a:hover {
    text-decoration: underline;
  }